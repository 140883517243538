.large-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 50px);
    padding: 100px;
}

.main-container {
    display: flex;
    flex-direction: row;    
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* width: 50%; */
}

.typing-container {
    text-align: left;
    font-size: 32px;
    color: #35072E;
    font-family: 'Inter', sans-serif;
    font-weight: 200;
    /* width: 400px; */
}

.thank-you-message{
    font-size: 14px;
    color: #35072E;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    text-align: left;
}

.typed-cursor {
    color: #35072E;
    font-size: 32px;
    margin-bottom: -20px;
} 

.description-text {
    font-size: 16px;
    color: #35072E;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    text-align: left;
    width: 284px;
    max-width: 100vw;
}

.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height to center vertically */
}

.centered-input,
.centered-button {
    margin: 10px 0;
}

.centered-input {
    padding: 10px;
    font-size: 16px;
    width: 200px;
    max-width: 100vw;
    /* text-align: center; */
}

.centered-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

input {
    display: flex;
    gap: 8px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #35072E;
    color: #35072E;
    font-family: 'Inter', sans-serif;
}

input::placeholder {
    color: #35072E;
    opacity: 0.7;
}

input:focus {
    outline: none;
    border-bottom: 1px solid #35072E;
}

input::-webkit-input-placeholder { /* Chrome/Safari/Opera */
    color: #35072E;
}

input::-moz-placeholder { /* Firefox */
    color: #35072E;
}

input:-ms-input-placeholder { /* IE/Edge */
    color: #35072E;
}

input::placeholder { /* Modern browsers */
    color: #35072E;
}
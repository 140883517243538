.nav {
    background-color: #B9B3D5;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;

    /* position: fixed; */
    /* top: 0;
    left: 0;
    right: 0; */
    z-index: 1000;
}

.nav-brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

button {
    background-color: #35072E;
    border: none;
    padding: 10px 20px;
    color: white;
    border-radius: 100px;
}